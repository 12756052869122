/* Spinner.css */
.rotate-animation {
  animation: rotate 3s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
