@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');


/* Default light mode */
:root {
    --color-background: #f9f9f9;
    --color-container: #ffffff;
    --color-text: #505863;
    --color-btn: #677dcd ;
    --color-sbtn: #f3f3f3;
    --color-hoverSbtn: #ececec;
    --color-btntxt: #FCFFFD;
    --color-inputtxt: #203547;
    --color-selected: #677dcd;
    --color-line: #6781df ;
    --color-reference: #ffb300;
    --color-edit: #6FCF97;
    --color-delete: #ff4c4c;
    --color-gold: #FFD700;
    --color-silver: #C0C0C0;
    --color-bronze: #CD7F32;
    --color-great: #677dcd;
    --color-good: #4bbd5c;
    --color-average: #acc2b7;
    --color-bad: #ea7704;
    --color-veryBad: #d7191c;
    --color-buttonGradient: #3b82f6;
    --color-headerText: #383838;
}

/* Dark mode */
.dark {
    --color-background: #203547;
    --color-container: #253b4e  ;
    --color-text: #f1f1f1;
    --color-btn: #a9c3f1;
    --color-sbtn: #294155;
    --color-hoverSbtn: #273e52;
    --color-btntxt: #203547;
    --color-inputtxt: #203547;
    --color-selected: #a9c3f1;
    --color-line: #4DB6FF;
    --color-reference: #FFD700;
    --color-edit: #E6D7B7 ;
    --color-delete: #FF4C4C;
    --color-gold: #FFD700;
    --color-silver: #C0C0C0;
    --color-bronze: #CD7F32;
    --color-great: #5398ff;
    --color-good: #5ebb68;
    --color-average: #acc2b7;
    --color-bad: #ea7704;
    --color-verybad: #d7191c;
    --color-buttonGradient: #a9c3f1;
    --color-headerText: #ffffff;


}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }